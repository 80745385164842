<template>
  <v-card height="100%">
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-cellphone</v-icon>
      Quantidade de Dispositivos
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="reloadGet"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text v-if="!loading">
      <Bar
        :chartData="graficoData"
        :chartOptions="chartOptions"
        :styles="styles"
      />
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhuma Dispositivo Registrado
    </v-card-text>
  </v-card>
</template>

<script>
import { topDispositivos } from "@/api/admin/graficos/usuarios.js";

export default {
  name: "BoxTotalPosts",

  components: {
    Bar: () => import("@/views/home/graficos/Bar.vue"),
  },

  data() {
    return {
      graficoData: [],
      graficoLabel: [],
      graficoColor: [],
      chartOptions: {
        responsive: true,
      },
      styles: {
        width: "100%",
      },
      loading: false,
    };
  },

  methods: {
    async getTopDispositovos() {
      this.loading = true;
      try {
        const response = await topDispositivos();
        this.graficoData = {
          labels: ["Dispositivos"],
          datasets: [
            {
              label: "Iphone",
              backgroundColor: "#FFA07A",
              data: [response.iPhone],
            },
            {
              label: "Android",
              backgroundColor: "#20B2AA",
              data: [response.Android],
            },
            {
              label: "Sem informação",
              backgroundColor: "#D3D3D3",
              data: [response.SemAcesso],
            },
            {
              label: "Outros",
              backgroundColor: "#708090",
              data: [response.Outros],
            },
          ],
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    reloadGet() {
      this.top_dispositivos = [];
      this.getTopDispositovos();
    },
  },

  mounted() {
    this.getTopDispositovos();
  },
};
</script>

<style></style>
